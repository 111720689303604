@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital@1&family=Montserrat:wght@100;400;500&family=Poppins:ital,wght@1,300&family=Roboto:wght@300;400&family=Rubik+Glitch&family=Rubik:wght@600&family=Source+Sans+Pro&display=swap');

:root {
   --main-bg-color: #165809;
   --dark-text-color: #333;
   --bg-gray:rgba(224, 221, 221, 0.745);
   --bg-black-color: #e5980f;
   --white-text-color: #FFF;
   --font-family: 'Montserrat', sans-serif;
   --box-shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;;
   --border: rgba(0, 0, 0, .1);

}

* {
   box-sizing: border-box;
   padding: 0;
   margin: 0;
   font-family: var(--font-family);

}
a{
   color: #2d2d2d;
}
li {
   list-style-type: none;

}

a,
a:hover {
   text-decoration: none;
}

#auth_header {
   position: relative;
}

#auth_header .navbar {
   background-color: var(--white-text-color);
   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.scroll  .navbar{
   animation: scrol2 0.4s linear;
}
.scroll.fixed  .navbar{
   position: fixed;
   width: 100%;
   z-index: 5;
   top: 0;
   animation: scrol 0.4s linear;
}
@keyframes scrol {
   0%{
      transform: translateY(-100%);
   }

   100%{
      transform: translateY(0px);
   }
}

@keyframes scrol2 {
   0%{
      transform: translateY(-100%);
   }

   100%{
      transform: translateY(0px);
   }
}
#auth_header .navbar ul {
   display: flex;
   flex-direction: row;
   gap: 20px;
}

#auth_header .navbar .brand_logo {
   width: 120px;

}

#auth_header .navbar li a {
   position: relative;
   text-transform: capitalize;
   color: var(--main-bg-color);
   font-weight: 500;
   font-size: 18px;
}

#auth_header .navbar li a::before {
   content: "";
   position: absolute;
   top: 100%;
   height: 1.6px;
   transition: all 0.4s ease-in-out;
   transform: scale(0, 0);
   width: 100%;
   background-color: var(--main-bg-color);
   left: 0;
   border-radius: 4px;

}

#auth_header .navbar li a.active::before {
   transform: scale(1, 1);
}

#auth_header .navbar li a:hover::before {
   transform: scale(1, 1);
}

#auth_header #mobile_versions .nav_mobile li a {
   position: relative;
   text-transform: capitalize;
   color: var(--main-bg-color);
   font-weight: 500;
   font-size: 18px;
}

#auth_header #mobile_versions .nav_mobile li{
   margin: 10px 0px;
}

#auth_header #mobile_versions .nav_mobile li a::before {
   content: "";
   position: absolute;
   top: 100%;
   height: 1.6px;
   transition: all 0.4s ease-in-out;
   transform: scale(0, 0);
   width: 100%;
   background-color: var(--main-bg-color);
   left: 0;
   border-radius: 4px;

}

#auth_header #mobile_versions .nav_mobile li a.active::before {
   transform: scale(1, 1);
}

#auth_header #mobile_versions .nav_mobile li a:hover::before {
   transform: scale(1, 1);
}

#auth_header #mobile_versions .close_icon{
    /* position: absolute; */
    right: 32px;
    width: 40px;
    height: 40px;
    background: var(--main-bg-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    margin-left: auto;
    margin-right: 10px;
}





/* this is footer code */

footer .footer_wrap {
   background-color:var(--main-bg-color);
   color: var(--white-text);
   padding: 30px 20px 20px 20px;
   margin-top: 50px;

}

footer .footer_wrap .panel_logo_footer img {
   width: 150px !important;
   margin-top: 0px !important;
}

footer .footer_wrap .panel_logo {
   background-color: #000;
   padding: 10px;
   border-radius: 10px;
   width: fit-content;
}

footer .footer_wrap .TEXT_CONETNT {
   text-transform: capitalize;
   margin-top: 25px;
   color: var(--white-text-color);


}

footer .footer_wrap .text-center .copyright {
   margin-top: 80px;
   margin-bottom: 15px;
}

footer .footer_wrap .links_sites a {
   color: var(--white-text);
   font-size: 15px;
   text-transform: capitalize;
   margin-bottom: 10px;
   color: var(--white-text-color);
   position: relative;
}
footer .footer_wrap .links_sites a::before{
   content: "";
   width: 100%;
   position: absolute;
   top: 100%;
   left: 0;
   height: 2px;
   border-radius: 4px;
   background-color: var(--white-text-color);
   transition: all 0.4s ease-in-out;
   transform: scale(0,0);
}
footer .footer_wrap .links_sites a::after{
   content: "\2192";
   width: 100%;
   position: absolute;
left: -25px;
   

   transition: all 0.4s ease-in-out;
   visibility: hidden;
   opacity: 0;
   transform:translateX(20px);
}
footer .footer_wrap .links_sites a:hover::after{
   visibility: visible;
   opacity: 1;
   transform:translateX(0px);
}
footer .footer_wrap .links_sites a:hover::before{
   transform: scale(1,1);
}
footer .footer_wrap .links_sites div[class*='col'] {
   margin-bottom: 15px;
}
.social_links .heading_social h3{
   text-transform: capitalize;
   color: var(--white-text-color);
}
.social_links ul{
   display: flex;
   gap: 20px;
   flex-direction: row;
   padding: 2px;
}
.social_links ul li a{
   position: relative;
   color: var(--white-text-color);
   z-index: 1;
}
.social_links ul li a .icon{
position: relative;
color: #fff;
width: 40px;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
background-color: var(--white-text-color);
color: var(--main-bg-color);
border-radius: 10px;
transition: all 0.4s linear;
z-index: 1;
}
.social_links ul li a .icon::before{
   content: "";
   position: absolute;
   width: 40px;
   height: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 10px;
   background-color: var(--bg-black-color);
   color: var(--main-bg-color);
   z-index: 1;
   transform: scale(0,0);
   transition: all 0.4s linear;
   border:5px solid var(--bg-black-color);
}
.social_links ul li a .icon:hover::before{
   transform: scale(1,1);
   z-index: 1;
   color: #fff;
   
}
.social_links ul li a .icon:hover{
   color: var(--white-text-color);
   z-index: 2;
}
.social_links ul li a:hover::before{
   color: var(--white-text-color);
}
.social_links ul li a i{
   color: var(--white-text-color);
}
.panel_logo_footer {
   position: relative;
   text-transform: capitalize;

}
.panel_logo_footer a{
   display: flex;
}
.panel_logo_footer .food{
   font-weight: 900;
   color: var(--bg-black-color);
   padding: 0px;
   font-family: cursive;
}
.panel_logo_footer .bakit{
   font-weight: 700;
   color: var(--white-text-color);
   padding: 0px;
}
.secondary_footer{
   background-color: var(--bg-black-color);
   color: var(--white-text-color);
   padding: 10px;
}


/* this is global css */
body .form-control{
   background-color: #fff;
   border-radius: 20px;
   height: 45px;
  transition: all 0.4s ease-in-out;

}
body .form-control:focus{
   border-color: var(--bg-black-color);
   outline: none;
   box-shadow: none;
}
.heading_main{
   display: flex;
   justify-content: center;
   align-items: center;
}
.heading_main h1{
   text-transform: capitalize;
   font-weight: 700;
   margin: 20px 0px;
   border-bottom: 2px solid var(--bg-black-color);
   padding-bottom: 10px;
   border-radius: 2px;
}
.card_wrap{
   background-color: var(--white-text-color);
   border-radius: 15px;
   border: 1px solid var(--border);
   position: relative;
   text-transform: capitalize;
   margin: 10px;
}
.card_body{
   padding: 10px;
   position: relative;
   text-transform: capitalize;
}

img{
   width: 100%;
   max-width: 100%;
}

.btn_main{
   background-color: var(--bg-black-color) !important;
   color: var(--white-text-color) !important;
   padding: 10px 20px !important;
   border-radius: 40px !important;
   margin: 20px 0px !important;
   text-transform: capitalize;
   font-weight: 500 !important;
   border: 1px solid transparent !important;
   position: relative;
   z-index: 1;
   transition: all 0.4s ease-in-out;
}

/* .btn_main::before{

   content: "";
   position: absolute;
   left: 0;
  width: 0;
  height: 0;
  top: 0;
   background-color: var(--main-bg-color);
   color: var(--white-text-color);
   border-radius: 40px;
border: 1px solid var(--main-bg-color);
transition: all 0.4s ease-in-out;
z-index: 0;
opacity: 0;
visibility: hidden;
}

.btn_main:hover::before{
   width: 100%;
   height: 100%;
   transition: all 0.4s ease-in-out;
   z-index: 1;
   opacity: 1;
visibility: visible;
} */

.slick-dots li button:before {
   font-size: 6px;
   line-height: 20px;
   position: absolute;
   top: 0;
   left: 0;
   width: 35px;
   height: 5px;
   content: '';
   text-align: center;
   opacity: 1;
   color: #000;
   background:var(--bg-black-color);
   border-radius: 30px;
}
.slick-dots li {
   position: relative;
   display: inline-block;
   width: 50px;
   height: 20px;
   margin: 0 5px;
   padding: 0;
   cursor: pointer;
   transition: all 0.4s ease-in-out;
   transform: scale(0.8,0.8);
}
.slick-dots li.slick-active button:before {
   opacity: 1;
   color: var(--bg-black-color);
   background-color: var(--main-bg-color);
   transform: scale(1,1);
}
.video-react .video-react-big-play-button {
   font-size: 3em;
   line-height: 0px;
   height: 40px !important;
   width: 40px !important;
   border-radius: 50% !important;
   display: block;
   position: absolute !important;
   top: 50% !important;
   left: 50% !important;
   transform: translate(-50%, -50%) !important;
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
   border-color: var(--bg-black-color) !important;
}
.video-react-icon-play-arrow:before, .video-react .video-react-bezel .video-react-bezel-icon-play:before, .video-react .video-react-play-control:before, .video-react .video-react-big-play-button:before {
   content: "";
   /* position: absolute; */
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
}
.video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {
   width: 100% !important;
   max-width: 100% !important;
   height: 0;
   border-radius: 15px !important;
}
svg:not(:host).svg-inline--fa, svg:not(:root).svg-inline--fa {
   z-index: inherit;
   overflow: visible;
   box-sizing: content-box;
}

.info_aboutus .accordion-flush .accordion-item {
 background-color: var(--bg-black-color);
 color: var(--white-text-color);
 margin: 20px 0px;
}

.info_aboutus .accordion-header {
   margin-bottom: 0;

}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed{
   background-color: var(--bg-black-color);
   outline: none;
   box-shadow: none;
   border: none;
   border-radius: 20px !important;
   color: #fff;
}
.info_aboutus .accordion-header{
   border-top-right-radius: 15px !important;
   border-top-left-radius: 15px !important;
}
.info_aboutus .accordion-flush .accordion-item{
   border-radius: 20px;
   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
   border-bottom-right-radius: 20px !important; 
    border-bottom-left-radius:20px !important;
}
.info_aboutus .accordion-button::after{
   content: "\27A5";
   transform: rotate(45deg);
   background-color: var(--main-bg-color);
   color: var(--white-text-color);
   width: 30px;
   height: 30px;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-image: none !important;
}
.accordion-button {

}






/* this is home page */
.indexs_page .main_pages_slider{
   overflow-x: hidden !important;
   overflow-y: hidden !important;
}
.indexs_page .blogs_latest{
   transition: all 0.4s ease-in-out;
   overflow: hidden;
   position: relative;
}
.indexs_page .blogs_latest .card_img img{
   border-radius: 15px;
   width: 100%;
   cursor: pointer;
   height: 200px;
   object-fit: cover;
}
.indexs_page .blogs_latest .card_img{
   position: relative;
}

.indexs_page .blogs_latest .card_details{
   position: absolute;
   bottom: 20px;
   width: 100%;
   padding: 10px;
   transform: translateY(30px);
   opacity: 0;
   visibility: hidden;
   transition: all 0.4s ease-in-out;
}



.indexs_page  .blogs_latest:hover .card_img img{
   opacity: 0.8;
   filter: blur(2px);
}
.indexs_page  .blogs_latest  .card_details {
   display: flex;
   justify-content: center;
   align-items: center;
}
.indexs_page  .blogs_latest  .card_details h2{
   font-size: 16px;
   word-break: break-all;
   overflow: hidden;
   color: #000;
   margin: 10px 0px;
}
.indexs_page  .blogs_latest  .card_details .blog_detail{
   background-color: var(--main-bg-color);
   color: var(--white-text-color);
   padding: 10px 20px;
   text-transform: capitalize;
   font-weight: 700;
   border-radius: 15px;
   margin: 10px 0px;
}
.indexs_page  .blogs_latest:hover  .card_details{
   transform: translateY(0px);
   opacity: 1;
   visibility: visible;
   display: block;
}

.indexs_page  .lates_blogs{
   position: relative;
   margin: 20px 0px;
   transition: all 0.4s ease-in-out;
   
}
.indexs_page .popular_blogs{
   position: relative;
   margin: 20px 0px;
   transition: all 0.4s ease-in-out;

}


.indexs_page .filter_btn_wrap {
   display: grid;
   gap: 8px 30px;
   grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
}
.indexs_page  .filter_btn_wrap .btn_filter {
   border-radius: 15px;
   border: 1px solid #e6e6e6;
   background-color: #ffffff;
   display: flex;
   align-items: center;
   padding: 14px;
   transition: all 0.4s;
}

.indexs_page  .filter_btn_wrap .btn_filter:hover{
   transform: translateY(-10px);
   border: 1px solid var(--bg-black-color);
   box-shadow: var(--box-shadow);
}

.indexs_page  .filter_btn_wrap .btn_filter .filter_txt {
   padding-left: 20px;
   text-transform: capitalize;
   color: var(--bg-black-color);
}

.indexs_page  .filter_btn_wrap .btn_filter img {
   width: 30px;
   height: 30px;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: var(--bg-btn-blue);
   color: var(--white-text);
}



.indexs_page  .category_blogs{
   margin: 20px 0px;
   text-transform: capitalize;
   position: relative;
}
.indexs_page .info_aboutus{
   margin: 20px 0px;
   text-transform: capitalize;
   position: relative;
}
.indexs_page .next_plan{
   margin: 20px 0px;
   text-transform: capitalize;
   position: relative; 
}
.slide_for_next_step .imgs{
   transition: all 0.4s ease-in-out;
   position: relative;
   cursor: pointer;
}
.slide_for_next_step .imgs img{
   width: 100% !important;
   height: 600px;
   object-fit: cover;
   border-radius: 30px;
   position: relative;
   cursor: pointer;
   transition: all 0.4s ease-in-out;
}
.slide_for_next_step .imgs:hover img{
   filter: blur(10px);
   background-color: rgba(65, 65, 65, 0.745);
   opacity: 0.6;
}
.slide_for_next_step .texts{
   position: absolute;
   top: 50%;
   color: var(--dark-text-color);
   padding: 100px;
   opacity: 0;
   visibility: hidden;
   transform: scale(0,0);
   transition: all 0.4s ease-in-out;
   font-size: 30px;
}
.slide_for_next_step .imgs:hover  .texts{
   opacity: 1;
   visibility: visible;
   transform: scale(1,1);
}
/* conatct us page */
.box_contactus {
   text-transform: capitalize;
   font-family: var(--font-family);
   position: relative;
   }
   .box_contactus  .main_heading{
       color: var(--main-bg-color);
       font-size: 30px;
       font-weight: 900;
       margin: 20px 0px;
       text-align: center;
     
       position: relative;
   }
   
   .box_contactus  .main_heading::before{
       content: "";
       position: absolute;
       height: 2px ;
       top: 100%;
     
       background-color: var(--main-bg-color);
       
       padding: 1px;
   }
   
   .conatct_form .form-label{
       color: var(--main-bg-color);
       font-family: var(--font-family);
       font-weight: 700;
   
       text-transform: capitalize;
   }
   

   .conatct_form .form_group{
       margin: 10px 0px;
   }
   
   form-control.is-valid, .was-validated .form-control:valid {
    
       padding-right: calc(1.5em + 0.75rem);
       box-shadow: none;
       outline: none;
   
   }
   .conatct_form .text_area{
       max-height: 200px;
       max-height: 200px;
       height: 200px;
   }
   .form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
       box-shadow: none;
       outline: none;
       border-color: var(--main-bg-color) !important;
   }
   
   .form-control.is-valid:focus, .was-validated .form-control:valid:focus {
       box-shadow: none;
       outline: none;
       border-color: var(--main-bg-color) !important;
   }
   
   .form-check-input:checked {
       background-color: var(--main-bg-color) !important;
       border-color: #FFF !important;
       outline: none;
       box-shadow: none;
   }
   .form-check-input:checked[type=checkbox]{
       outline: none;
       box-shadow: none;
   }


/* about us page */

/* page40 us page */

.page_404 {
   padding: 40px 0;
   background: #fff;
   font-family: 'Arvo', serif;
}

.page_404 img {
   width: 100%;
}

.four_zero_four_bg {

   background-image: url("../Images/page404.gif");
   height: 400px;
   background-position: center;
}


.four_zero_four_bg h1 {
   font-size: 80px;
}

.four_zero_four_bg h3 {
   font-size: 80px;
}

.link_404 {
   color: #fff !important;
   padding: 10px 20px;
   background: var(--main-bg-color);
   margin: 20px 0;
   font-weight: 700;
   font-size: 20px;
   text-transform: capitalize;
   display: inline-block;
}

.contant_box_404 {
   margin-top: -50px;
}

.params{
   color: var(--main-bg-color);
   text-transform: capitalize;
   font-weight: 700;
   font-size: 20px;
}
.params_not{
   color: var(--main-bg-color);
   text-transform: capitalize;
   font-weight: 700;
   font-size: 20px;
   text-align: center;  
}


/* terms page css */

.terms_condition{
   position: relative;
   text-transform: capitalize;
   margin: 20px 0px;
}

.terms_condition .main_heading{
   color: var(--dark-text-color);
   font-weight: 700;
   font-size: 30px;
   font-family: var(--font-family);
   text-align: center;
}
.terms_condition  .main_heading .and{
   color: var(--main-bg-color);
   font-style: italic;
}
.terms_condition .headings_bar h2{
margin: 25px 0px;
color: var(--main-bg-color);
font-size: 18px;
font-weight: 700;
font-family:cursive;
}
.terms_condition .headings_ba .paragraph{
   font-family:var(--font-family);
   font-weight: 400;
}



















/* the contact us page */

.contact_uspage .right_conatct_social_icon{
   background:var(--bg-black-color);
}
.contact_uspage  .contact_us{
  background-color: #f1f1f1;
  padding: 120px 0px;
}

.contact_uspage .contact_inner{
  background-color: #fff;
  position: relative;
  box-shadow: 20px 22px 44px #cccc;
  border-radius: 25px;
}
.contact_uspage .contact_field{
  padding: 60px 340px 90px 100px;
}
.contact_uspage .right_conatct_social_icon{
  height: 100%;
}
.contact_uspage{
text-transform: capitalize;
}
.contact_uspage .contact_field h3{
 color: #000;
  font-size: 40px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: monospace;
}
.contact_uspage .contact_field p{
  color: #000;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 35px;
}
.contact_uspage .contact_field .form-control{
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #ccc;
}
.contact_uspage .contact_field .form-control:focus{
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid var(--bg-black-color);
}
.contact_uspage .contact_field .form-control::placeholder{
  font-size: 13px;
  letter-spacing: 1px;
}

.contact_uspage .contact_info_sec {
  position: absolute;
  background-color: #2d2d2d;
  right: 1px;
  top: 18%;
  height: 340px;
  width: 340px;
  padding: 40px;
  border-radius: 25px 0 0 25px;
  color: #ffff;
}
.contact_uspage  .contact_info_sec h4{
  letter-spacing: 1px;
  padding-bottom: 15px;
  font-family: monospace;
}

.contact_uspage  .info_single{
  margin: 30px 0px;
  gap: 20px;
}
.contact_uspage .info_single i{
  margin-right: 15px;
  color: var(--text--white) !important;
}
.contact_uspage .info_single span{
  font-size: 14px;
  letter-spacing: 1px;
  color: var(--text--white);
}

.contact_uspage .info_single span a{
   color: var(--text--white);
}

.contact_uspage  button.contact_form_submit {
  background: var(--bg-black-color);
  border: none;
  color: #fff;
  padding: 10px 15px;
  width: 100%;
  margin-top: 25px;
  border-radius: 35px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 2px;
}
.contact_uspage .socil_item_inner li{
  list-style: none;
}
.contact_uspage .socil_item_inner li a{
  color: #fff;
  margin: 0px 15px;
  font-size: 14px;
}
.contact_uspage .socil_item_inner{
  padding-bottom: 10px;
}

.contact_uspage .map_sec{
  padding: 50px 0px;
}
.contact_uspage .map_inner h4, .map_inner p{
  color: #000;
  text-align: center
}
.contact_uspage .map_inner p{
  font-size: 13px;
}
.contact_uspage .map_bind{
 margin-top: 50px;
  border-radius: 30px;
  overflow: hidden;
}

.blogs_page .divde_by_cat{
   background-color: var(--bg-gray);
   padding: 15px;
   border-radius: 15px;
   box-shadow: var(--box-shadow);
   width: 100%;
height: 100%;
}

.divde_by_cat .slect_picker .filter_dropdown .btn{
   background-color: var(--bg-black-color);
   color: var(--white-text-color);
   width: 100%;
   padding: 10px;
   border-radius: 15px;
   text-transform: capitalize;
   font-weight: 500;
}
.divde_by_cat .slect_picker .filter_dropdown .menu_filter li button{
   border: transparent;
   background-color: transparent;
   text-transform: capitalize;
   outline: none;
   box-shadow: none;
   padding-left: 10px;
   padding-top: 4px;
   word-break: break-all;
}
.divde_by_cat .slect_picker .filter_dropdown .menu_filter li button:hover{
color: #2d2d2d;
}
.divde_by_cat .slect_picker .filter_dropdown .menu_filter{
   display: none;
   background: #fff;
   margin-top: 10px;
   padding: 10px;
   text-transform: capitalize;
   border-radius: 15px;
  box-shadow: var(--box-shadow);
}
.divde_by_cat .slect_picker .filter_dropdown .menu_filter{
   height: 200px;
   overflow-y: auto;
}
.divde_by_cat .slect_picker .filter_dropdown .menu_filter.active{
   animation: scale 0.4s linear;
   display: block;
   overflow-y: auto;
   overflow-x: hidden;
}
.divde_by_cat .slect_picker .filter_dropdown .menu_filter.active::-webkit-scrollbar{
   width: 2px;
}
@keyframes scale {
   0%{
      transform: scale(0,0);
   }
   100%{
      transform: scale(1,1);
   }
}

.divde_by_cat .search_bar {
   margin-top: 50px;
}

.divde_by_cat .search_bar .btn_secondary{
   border-bottom-right-radius: 25px !important;
   border-top-right-radius: 25px !important;
   background-color: var(--bg-black-color) ;
   color: var(--white-text-color);
} 

.blogs_page .card_body img{
   height: 200px;
   width: 100%;
   border-radius: 20px;
   object-fit: cover;
}
.blogs_page .card_body .card_details{
   margin: 20px 0px;
   text-transform: capitalize;
}

.blogs_page .card_body .blog_detail {
   color: var(--bg-black-color);
   font-weight: 700;
}

.pagination {
   display: flex;
   justify-content: center;
   align-items: center;
}

.pagination ul li a{
   border: 1px solid var(--bg-black-color);
   color: var(--bg-black-color);

}
.pagination ul{
   display: flex;
}
.pagination ul li.active a{
   background-color: var(--bg-black-color);
   color: var(--white-text-color);
   border-color: transparent;
}

.clips .card_body{
   height: 180px;
   overflow: auto;
}

.clips .card_body::-webkit-scrollbar{
   background-color: transparent;
   width: 0px;
   display: block;
   touch-action: auto;
}

.clips .card_body::-webkit-scrollbar-thumb{
   background-color: transparent;
}

.clips .card_body::-webkit-scrollbar-track{
   background-color: transparent;
   height: 5px;
}


::-webkit-scrollbar-thumb{
   background-color: #e5980f !important;
   border-radius: 10px;
   height: 4px;
}

::-webkit-scrollbar-track{
   background-color:transparent !important;
   height: 5px;
}

html::-webkit-scrollbar-thumb{
   background-color: #e5980f !important;
   border-radius: 10px;
   height: 4px;
}

/* width */
::-webkit-scrollbar {
   width: 5px;
 }
 
 /* Track */
 ::-webkit-scrollbar-track {   background-color:transparent !important;
   height: 5px;
 }
 
 /* Handle */
 ::-webkit-scrollbar-thumb {
   background-color: #e5980f !important;
   border-radius: 10px;
 }
 
 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: #165809;
 }

 .wrap_icon .icon{
   background-color: var(--bg-black-color);
   color: #fff !important;
   width: 40px;
   height: 40px;
   border-radius: 10px;
   display: grid;
   place-items: center;
   font-size: 20px;
   cursor: pointer;
 }

 .wrap_icon{
   position: fixed;
    bottom: 100px;
    right: 20px;
    z-index: 20;
 }
 #scroll_down{
   display: none;
 }
 #scroll_down.active{
   display: block;
   animation: scroll 0.4s linear;
 }

 @keyframes scroll {
   0%{
transform: scale(0,0);
   }

   100%{
      transform: scale(1,1);
   }
 }

footer .TEXT_CONETNT a{
   color: #fff;
 }

 footer .TEXT_CONETNT a:hover{
   color: #fff;
   text-decoration: underline !important;
 }


 html ,body{
   overflow-x: hidden !important;
 }


 .container .slick-prev {
   left: -25px;
   opacity: 1;

}

.container .slick-next.slick-disabled:before, .container .slick-prev.slick-disabled:before {
   opacity: 1;
   display: flex;
   justify-content: center;
   align-items: flex-start;
   padding-top: 2px;
}

.main_pages_slider .slick-arrow{
   display: none !important;
}

.container .slick-next, .slick-prev {
   background-color: var(--bg-black-color) !important;
   color: #fff !important;
   display: block !important;
}
.container .slick-arrow {
   background-color: var(--bg-black-color);
   color: #fff;
}
.container .slick-next, .slick-prev {

margin: 20px;
}
.container .slick-prev {
   left: 40px;
   opacity: 1;

}



.slick-dots, .slick-next, .slick-prev {
   position: absolute;
   display: none;
   /* padding: 75px; */
   z-index: 20;
}
.slick-dots, .slick-next, .slick-prev {
   position: absolute;
   display: block;
   /* padding: 75px; */
   z-index: 20;
}
.slick-next, .slick-prev {
   background-color: var(--bg-black-color) !important;
   color: #fff !important;
}
.slick-arrow {
   background-color: var(--bg-black-color);
   color: #fff;
}
.slick-next, .slick-prev {
   font-size: 83px;
   line-height: 0;
   top: 50%;
   width: 20px;
   height: 20px;
   transform: translate(0,-50%);
   cursor: pointer;

   z-index: 2;
}
.slick-next {
   left:80px;
   opacity: 1;

}

.main_pages_slider .slick-next:before {
   display: none !important;
}

.main_pages_slider .slick-prev:before {
   display: none !important;
}
.main_pages_slider .slick-slider{
margin: 0px !important;
}
.blog_details .slick-slider{
   margin: 0px !important;
   }

   .blog_details .slick-prev:before {
      display: none !important;
   }
   .blog_details .slick-next:before {
      display: none !important;
   }
.slick-next:before {
   content: '\00AB';
   background-color: #e5980f;
   color:#ffff;
   width: 30px;
   height: 30px;
   border-radius: 50%;
   visibility: visible;
   position: absolute;
   top: 0;
   display: flex;
   justify-content: center;
   align-items: flex-start;
   padding-top: 11px;
   line-height: 0px;
}

.container .slick-next.slick-disabled:before, .container .slick-prev.slick-disabled:before {
   opacity: 1;
   display: flex !important;
   justify-content: center !important;
   align-items: flex-start !important;
   padding-top: 11px !important;
}
.slick-prev{
   visibility: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
}
.slick-next{
   visibility: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
}

.slick-arrow{
   background-color: #e5980f;
   color:#ffff;
   width: 30px;
   height: 30px;
   border-radius: 50%;

}
.slick-prev:before {
   content: '\00BB';
   background-color: #e5980f;
   color:#ffff;
   width: 30px;
   height: 30px;
   border-radius: 50%;
   visibility: visible;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
  position: absolute;
  top: 0;
  padding-top: 11px;
  line-height: 0px;
}
.slick-prev {
   left: 0px;
   opacity: 1;

}
.slick-next, .slick-prev {
margin-top: 20px;
 
   z-index: 2;
}

.slick-arrow{
background-color: var(--bg-black-color);
color: #fff;
}
.slick-next, .slick-prev {
   font-size: 83px;
   line-height: 0;
top: 100%;
}
.slick-slider{
   margin: 20px 0px;
}

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
   background-color: var(--bg-black-color);
   color: #fff;
}

.slick-next, .slick-prev{
   background-color: var(--bg-black-color) !important;
color: #fff !important;
}



/* .slick-dots, .slick-next, .slick-prev {
   position: absolute;
   display: none;

   z-index: 20;
} */
