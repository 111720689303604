:root {
     --main-bg-color: #165809;
     --dark-text-color: #333;
     --bg-gray:rgba(224, 221, 221, 0.745);
     --bg-black-color: #e5980f;
     --white-text-color: #FFF;
     --font-family: 'Montserrat', sans-serif;
     --box-shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;;
     --border: rgba(0, 0, 0, .1);
  
  }

  .blog_details{
     padding-top: 100px;
  }
  .blog_details .blog_slider .top_slide img{
     height: 400px;
     object-fit: cover;
     cursor: pointer;
     padding: 15px;
     border-radius: 30px !important;
  }
  .blog_details .blog_slider .bottom_slider img{
     padding: 15px;
     border-radius: 30px !important;
     height: 170px;
     object-fit: cover;
     cursor: pointer;
  }

  .blog_details .main_headings_blogs {
   background-color: rgb(193 193 193 / 10%);
     width: 100%;
     height: fit-content;
     margin-top: 15px;
     padding: 15px;
     border-radius: 15px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .blog_details .main_headings_blogs .heading_blog .key{
     color: var(--main-bg-color);
     font-size: 30px;
     margin: 10px 0px;
     text-transform: capitalize;
     font-weight: 700;
  }
  .blog_details .main_headings_blogs .value{
     text-transform: capitalize;
     font-weight: 400;
     color: var(--dark-text-color);
}
.blog_details .main_headings_blogs .value .star{
     color: var(--bg-black-color);
     padding: 10px;
  }

  .blog_details .blog_others_details .head{
     display: flex;
     justify-content: center;
     margin: 40px 0px;
  }

  .blog_details .blog_others_details .head h1{
     color: var(--bg-black-color);
     border-bottom: 1px solid var(--main-bg-color);
     padding-bottom: 5px;
     width: fit-content;
     text-transform: capitalize;
     font-weight: 600;
  }

  .blog_details .blog_wrapper_details  .icon{
width: 30px;
height: 30px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
background-color: var(--bg-black-color);
color: var(--white-text-color);
  }

  .blog_details .blog_wrapper_details .heading p{
     margin: 20px 0px;
     font-weight: 600;
     color: var(--main-bg-color);
     text-transform: capitalize;
     font-size: 25px;
  }

  .blog_details .blog_wrapper_details .texts{
     text-transform: capitalize;
     color: var(--dark-text-color);
  }